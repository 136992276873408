//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { ProjectModal, Sidebar, NavigationBar } from '../GeneralComponents';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import leftButton from '../../Images/left-button.svg'
import rightButton from '../../Images/right-button.svg'

import PGStats from '../../Images/PGStats.jpg'
import YouTube from '../../Images/YouTube.png'

import TVWH1 from '../../Images/Projects/TVWH.PNG'
import TVWH2 from '../../Images/Projects/TVWH2.PNG'
import TVWH3 from '../../Images/Projects/TVWH3.PNG'
import TVWH4 from '../../Images/Projects/TVWH4.PNG'

import IdaGem from '../../Images/Projects/IdaGem.PNG'
import IdaGem2 from '../../Images/Projects/IdaGem2.PNG'
import IdaGem3 from '../../Images/Projects/IdaGem3.PNG'
import IdaGem4 from '../../Images/Projects/IdaGem4.PNG'

import VentureCollege from '../../Images/Projects/VentureCollege.PNG'
import VentureCollege2 from '../../Images/Projects/VentureCollege2.PNG'
import VentureCollege3 from '../../Images/Projects/VentureCollege3.PNG'

import FATE1 from '../../Images/Projects/FATE.png'

import DrumDuel from '../../Images/Projects/DrumDuel.PNG'

import SnS from '../../Images/Projects/SnS.PNG'

import Agent131 from '../../Images/Projects/Agent13.PNG'

import MINUS1 from '../../Images/Projects/MINUS.png'

import resume from '../../Files/JC_Ho_Resume.pdf'

import AWS from '../../Images/SkillsIcons/AWS.png'
import CSharp from '../../Images/SkillsIcons/CSharp.png'
import CSS from '../../Images/SkillsIcons/CSS.png'
import HTML from '../../Images/SkillsIcons/HTML.png'
import Java from '../../Images/SkillsIcons/Java.png'
import JavaScript from '../../Images/SkillsIcons/JavaScript.png'
import MySQL from '../../Images/SkillsIcons/MySQL.png'
import Node from '../../Images/SkillsIcons/Node.png'
import ReactLogo from '../../Images/SkillsIcons/React.png'
import Rust from '../../Images/SkillsIcons/Rust.png'
import Unity from '../../Images/SkillsIcons/Unity.svg'
import Unreal from '../../Images/SkillsIcons/Unreal.svg'

const skills = [
    { name: "AWS", image: AWS },
    { name: "Rust", image: Rust },
    { name: "MySQL", image: MySQL },
    { name: "HTML", image: HTML },
    { name: "CSS", image: CSS },
    { name: "JavaScript", image: JavaScript },
    { name: "Node.js", image: Node },
    { name: "React.js", image: ReactLogo },
    { name: "Java", image: Java },
    { name: "C#", image: CSharp },
    { name: "Unity", image: Unity },
    { name: "Unreal Engine", image: Unreal },
];
  
// TEMPLATE
// { name: '', images: [], description: '', type: '', role: '', date: '', video: '' },

let projects = [
    { name: 'Treasure Valley Water Hub', images: [ TVWH1, TVWH2, TVWH3, TVWH4 ], description: 'A React website to bring all Treasure Valley water data, tools, and maps into one place for easy accessibility and use.', type: 'Website', role: 'Full Stack Developer', date: 'SUMMER 2024', video: '' },
    { name: 'IdaGem', images: [ IdaGem, IdaGem2, IdaGem3, IdaGem4 ], description: 'A learning website with a Unity game attached for students in Idaho.', type: 'Website', role: 'Back-End Developer', date: 'SUMMER 2024', video: '' },
    { name: 'Venture College', images: [ VentureCollege, VentureCollege2, VentureCollege3 ], description: 'A website developed for judges to be able to have a place to leave scores and comments on groups and teams in large competitions.', type: 'Website', role: 'Back-End Developer', date: 'SPRING 2025', video: '' },
    { name: 'F.A.T.E.', images: [ FATE1 ], description: "A React website created for teachers to rate other academic professional's portfolios.", type: 'Website', role: 'Front-End Developer', date: 'SPRING 2024', video: '' },
    { name: 'ULT MINUS', images: [ MINUS1 ], description: 'A casual competitive mod created by me for Smash Ultimate.', type: 'Game Mod', role: 'Dev Lead', date: '2024-2025', video: 'https://www.youtube.com/embed/KGJBI8S4_wE?si=eOOi3CcxjvIYxY4M' },
    { name: 'Drum Duel', images: [ DrumDuel ], description: 'An accessible arcade multiplayer drum rhythm game made in Unreal Engine.', type: 'Game', role: 'Game Developer', date: 'FALL 2024', video: 'https://www.youtube.com/embed/ca9De-QWJcY?si=GzGVGAwO0XZStALY' },
    { name: `Slurgin N' Snop`, images: [ SnS ], description: 'A networked multiplayer game about a Snail and a Slug working together made in Unreal Engine.', type: 'Game', role: 'Game Developer', date: 'FALL 2024', video: 'https://www.youtube.com/embed/yASwTYEKEWs?si=C4pjmV0bXGz3ML1h' },
    { name: 'Agent 13', images: [ Agent131 ], description: 'A Unity VR Action/Stealth game.', role: 'Game Developer', date: 'FALL 2023-SPRING 2024', type: 'VR Game', video: 'https://www.youtube.com/embed/3_IecVpGBtg?si=STVaxKPOhgOAt7b1' },
]

const Home = ({ aboutRef, projectRef, resumeRef }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState({ name: '', images: [], video: '' });
    const [swiperInstance, setSwiperInstance] = useState(null);
  
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const handleSlideClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
      };
    
      const handleClose = () => {
        setShowModal(false);
      };

    return (
        <Fragment>
            <NavigationBar scrollToRef={scrollToRef} aboutRef={aboutRef} projectRef={projectRef} resumeRef={resumeRef} />
            <Sidebar scrollToRef={scrollToRef} aboutRef={aboutRef} projectRef={projectRef} resumeRef={resumeRef} />
            <Container fluid className='home'> 
                { /* ABOUT SECTION */ }
                <br/>
                <Row className='justify-content-center about-section' ref={aboutRef}>
                    <Col xs md={3} className='bubble1'>
                        <Row className='justify-content-center skills-bubble'>
                            <h2>Skills</h2>
                            <hr/>
                            <div className='logo-container'>
                                {Array.from({ length: Math.ceil(skills.length / 3) }, (_, rowIndex) => (
                                    <Row key={rowIndex} className='justify-content-center'>
                                        {skills.slice(rowIndex * 4, rowIndex * 4 + 4).map((skill, colIndex) => (
                                            <Col key={colIndex} xs sm={3}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{skill.name}</Tooltip>}>
                                                    <img src={skill.image} alt={skill.name} className='skill-logo'/>
                                                </OverlayTrigger>
                                            </Col>
                                        ))}
                                    </Row>
                                ))}
                            </div>
                        </Row>
                    </Col>
                    <Col className='text-bubble' md={5}>
                        <h1>About Me</h1>
                        <hr className='bubble-hr'></hr>
                        <p>
                        Hello! I am JC Ho! I am a Senior at Boise State University currently enrolled in the Games, Interactive Media, and Mobile program/major. My skills are mainly in Web Development and Software Development. I have made a few projects in React and hope to continue growing my Web Development skills. I also have developed a few games and also have made my own mods. I really enjoy creating software and hope to have a future in it where I can make things truly impactful and things that can help. I always look for chances to improve myself and my portfolio, so I hope to see this website grow as I do!
                        </p>
                    </Col>
                    <Col xs md={3} className='bubble2'>
                        <Row className='justify-content-center other-bubble'>
                            <h2>Other</h2>
                            <hr/>
                        </Row>
                        <div className='other-bubble-info'>
                            <Row className='justify-content-center other-bubble-info-row'>
                                <Col xs md={1}>
                                </Col>
                                <Col xs md={3}>
                                    <a href='https://www.pgstats.com/ultimate/player/leiadog?id=S496244' target='_blank'>
                                        <img src={PGStats}></img>
                                    </a>
                                </Col>
                                <Col xs md={7}>
                                    <h2>Smash Ultimate Data</h2>
                                    <p>I play competitive Smash Ultimate!</p>
                                </Col>
                                <Col xs md={1}>
                                </Col>
                            </Row>
                            <Row className='justify-content-center other-bubble-info-row'>
                                <Col xs md={1}>
                                </Col>
                                <Col xs md={7}>
                                    <h2 className='float-right'>JC Ho YouTube</h2>
                                    <p className='float-right'>I enjoy making videos!</p>
                                </Col>
                                <Col xs md={3}>
                                    <a href='https://www.youtube.com/@leiadog11' target='_blank'>
                                        <img className='float-right' src={YouTube}></img>
                                    </a>
                                </Col>
                                <Col xs md={1}>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                
                { /* PROJECT SECTION */ }
                <Row className='justify-content-center project-section' ref={projectRef}>
                    <h1>Projects</h1>
                    <hr className='section-hr'></hr>
                    <Swiper 
                        className='project-swiper'
                        modules={[Navigation, Autoplay]}
                        navigation={{
                            nextEl: '.next',
                            prevEl: '.prev',
                        }} 
                        autoplay={{
                            delay: 3000, 
                            disableOnInteraction: true,
                        }}
                        slidesPerView={2}
                        setWrapperSize={false}
                        spaceBetween={50}
                        loop={true}
                        onSwiper={(swiper) => setSwiperInstance(swiper)} 
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 35,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 50,
                            },
                          }}>

                        {projects.map((project, index) => (
                            <SwiperSlide key={index} className='slide' onClick={() => handleSlideClick(project)} onMouseEnter={() => swiperInstance?.autoplay.stop()} onMouseLeave={() => swiperInstance?.autoplay.start()}>
                            <h1 className='project-title'>{project.name}</h1>
                            <img src={project.images[0]} />
                            <p className='description'>{project.description}</p>
                            <div className='extra-details'>
                                <p><b>Type</b> <br/> {project.type}</p>
                                <p><b>Role</b> <br/> {project.role}</p>
                                <p><b>Date</b> <br/> {project.date}</p>
                            </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className='swiper-buttons'>
                        <img className="prev" src={leftButton}></img>
                        <img className="next" src={rightButton}></img>
                    </div>

                    {showModal && (
                        <ProjectModal
                        name={selectedProject.name}
                        images={selectedProject.images}
                        video={selectedProject.video}
                        show={showModal}
                        handleClose={handleClose}
                        />
                    )}
                </Row>

                { /* RESUME SECTION */ }
                <Row className='justify-content-center resume-section' ref={resumeRef}>
                    <h1>Resume</h1>
                    <hr className='section-hr'></hr>
                    <iframe class='resume' src={resume}></iframe>
                </Row>
                <br/>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Home);
export { connection as Home };